<template>
  <div class="related-resources w-full">
    <div class="related-resources-wrapper">
      <Card
        :display-type="displayType"
        :resources="resources"
        :title="title"
        :hideArrowButtons="hideArrowButtons"
        :loadMore="loadMoreProps"
        @fetchMore="$emit('loadMore')">
      </Card>
    </div>
  </div>
</template>

<script>
import Card from "./Card.vue";

export default {
  components: {
    Card,
  },
  props: {
    resources: {
      type: Array,
      required: true,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    hideArrowButtons: {
      type: Boolean,
      required: false,
      default: () => true
    },
    displayType: {
      type: String,
      required: false,
      default: () => 'carousel'
    },
    loadMoreProps: {
      type: Object,
      required: false,
      default: () => ({
        hasNextPage: false,
        totalResults: 0,
        totalViewed: 0,
        isFetching: false,
        show: false
      })
    }
  }
};
</script>

<style lang="scss">
.related-resources {
  &-wrapper {
    > * {
      > *:not(.NurseResourceCard-views) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 0.5rem;
        margin-top: 2rem;

        @media screen and (max-width: 768px) {
          & {
            grid-template-columns: 1fr
          }
        }

        > * { 
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
